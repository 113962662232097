<div class="swiper-container" #container *ngIf="!DISABLED">
  <swiper [config]="config" (swiper)="onSwiper($event)">
    <ng-template swiperSlide *ngFor="let work of worksList">
      <a class="showcase--slide" [routerLink]="['/works', 'project']" [queryParams]="{ id: work.id }">
        <picture>
          <img
            class="thumbnail"
            title="{{ work.title }}"
            src="{{ work.thumbnail_src.url }}"
            alt="{{ work.thumbnail_src.alt ? work.thumbnail_src.alt : work.title + ' thumbnail image' }}"
          />
        </picture>

        <h3 class="works-name" *ngIf="!this.hideTitle">{{ work.title }}</h3>
      </a>
    </ng-template>
  </swiper>

  <div class="navigation">
    <button class="prev" #prev (click)="prevSlide()">
      <svg width="1200pt" height="1200pt" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <path d="m900 600-600-292.8 145.2 292.8-145.2 292.8zm-547.2-240 492 240-492 240 118.8-240z"/>
      </svg>
    </button>

    <button class="next" #next (click)="nextSlide()">
      <svg width="1200pt" height="1200pt" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <path d="m900 600-600-292.8 145.2 292.8-145.2 292.8zm-547.2-240 492 240-492 240 118.8-240z"/>
      </svg>
    </button>
  </div>
</div>
