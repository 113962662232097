<div class="links__container" *ngIf="this.about || this.projects || this.contact">
  <a class="project-link small" routerLink="/about" *ngIf="this.about">
    <svg viewBox="0 0 100 100">
      <path d="M49.52,38.12c3.06,0,5.52-2.52,5.52-5.58c0-3-2.46-5.52-5.52-5.52S44,29.54,44,32.54C44,35.6,46.46,38.12,49.52,38.12z   M55.4,45.86c0-2.52-2.04-4.5-4.5-4.5h-4.5c-2.52,0-4.5,1.98-4.5,4.5c0,2.46,1.98,4.5,4.5,4.5V63.5c-2.52,0-4.5,2.04-4.5,4.5  c0,2.52,1.98,4.5,4.5,4.5h9c2.46,0,4.5-1.98,4.5-4.5c0-2.46-2.04-4.5-4.5-4.5V45.86z M50,5c24.84,0,45,20.16,45,45S74.84,95,50,95  C25.1,95,5,74.84,5,50S25.1,5,50,5z"></path>
    </svg>
    <h2>More About Me</h2>
  </a>

  <a class="project-link small" routerLink="/works" *ngIf="this.projects">
    <svg viewBox="0 0 1151.2498 959.99999">
      <g transform="translate(10150.158,-772.95808)">
        <path d="m -10150.158,1349.2081 0,-383.75006 128.125,0 128.1247,0 0,-96.25001 0,-96.25001 320.0001,0 320,0 0,96.25001 0,96.25001 127.5,0 127.5,0 0,383.75006 0,383.75 -575.6251,0 -575.6247,0 0,-383.75 z m 1058.7498,0 0,-293.75 -484.3751,0 -484.3747,0 0,293.75 0,293.75 484.3747,0 484.3751,0 0,-293.75 z m -252.5,-435.00006 0,-51.25001 -230,0 -230.0001,0 0,51.25001 0,51.25 230.0001,0 230,0 0,-51.25 z"></path>
      </g>
    </svg>
    <h2>View More Projects</h2>
  </a>

  <a class="project-link small" routerLink="/contact" *ngIf="this.contact">
    <h2>Contact Me</h2>
  </a>
</div>
