/**
 * not-found.component
 * @author Malik Tillman
 *
 * 2020
 * */
import { Component } from '@angular/core';

@Component({selector: 'not-found', templateUrl: './not-found.component.html', styleUrls: ['./not-found.component.scss']})
export class NotFoundComponent { }
