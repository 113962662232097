<div class="container">
  <h2>Contact Me</h2>

  <contact-form></contact-form>

  <contact-info></contact-info>

<!--
  <swiper-showcase
    [loop]="true"
    [perPage]="3"
    [ids]="[1,22,23,4]"
    [spacing]="15"
    [autoplay]="false"
    [delay]="1000"
    [hideTitle]="false"
    [arrows]="true"
  ></swiper-showcase>
  -->

  <swiper-toolbox></swiper-toolbox>

  <links [contact]="false" [projects]="true" [about]="true"></links>
</div>
