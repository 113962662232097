/**
 * contact.component
 * @author Malik Tillman
 *
 * 2020
 * */
import { Component } from '@angular/core';

@Component({selector: 'contact', templateUrl: './contact.component.html', styleUrls: ['./contact.component.scss']})
export class ContactComponent { }
