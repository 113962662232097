<div class="container" #container>
  <form #contactForm [formGroup]="contactGroup" data-netlify-recaptcha="true" data-netlify="true" name="contact" method="post">
    <div class="name">
      <label #nameControl class="form-item">
        <span>First Name <span class="required">*</span></span>
        <input type="text" formControlName="_name" required
               placeholder="First Name"
        >
      </label>

      <label #lastNameControl class="form-item">
        <span>Last Name</span>
        <input type="text" formControlName="_nameLast" placeholder="Last Name">
      </label>
    </div>

    <label #emailControl class="form-item">
      <span>Email <span class="required">*</span></span>
      <input type="email"  formControlName="_email" email required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="Email">
    </label>

    <label #bodyControl class="form-item">
      <span>Message <span class="required">*</span></span>

      <textarea rows="6" cols="50" formControlName="_body" placeholder="Message"></textarea>
    </label>

    <input type="button"  value="Submit" (click)="onSubmit(contactGroup)">

    <div data-netlify-recaptcha="true"></div>
  </form>

  <div #errors class="error-copy hidden" *ngIf="contactGroup.invalid">
    <p *ngIf="contactGroup.get('_name').errors?.required">
      Please enter your name
    </p>

    <p *ngIf="contactGroup.get('_email').errors">
      <span *ngIf="contactGroup.get('_email').errors?.required">
        Please enter an email address
      </span>

      <span *ngIf="contactGroup.get('_email').errors?.email">
        Email format not recognized
      </span>
    </p>

    <p *ngIf="contactGroup.get('_body').errors?.required">
      Please enter a message
    </p>
  </div>

  <div class="success" (click)="toggleSuccess()">
    <div class="copy-container">
      <h2>Thanks for getting in touch!</h2>
    </div>

    <div class="image-container">
      <img src="{{ success_media.url }}" alt="{{ success_media.id }}">
    </div>

    <div class="copy-container bottom">
      <p>Form received successfully.</p>
    </div>
  </div>

  <div class="error" (click)="toggleFail()">
    <div class="copy-container">
      <h2>Something went wrong</h2>
    </div>

    <div class="image-container">
      <img src="{{ error_media.url }}" alt="{{ error_media.id }}">
    </div>

    <div class="copy-container bottom">
      <p>This form unable to be received</p>
    </div>
  </div>
</div>

<button (click)="toggleSuccess()" *ngIf="false">
  toggle Success
</button>

<button (click)="toggleFail()" *ngIf="false">
  toggle Fail
</button>


