/**
 * social-icons.component
 * @author Malik Tillman
 *
 * 2020
 * */
import { Component } from '@angular/core';

@Component({selector: 'social-icons', templateUrl: './social-icons.component.html', styleUrls: ['./social-icons.component.scss']})
export class SocialIconsComponent { }
