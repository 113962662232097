<div class="hero">
  <three></three>

  <div class="right-panel">
    <swiper-showcase
      [loop]="true"
      [perPage]="1"
      [spacing]="0"
      [autoplay]="false"
      [delay]="5000"
      [hideTitle]="true"
      [arrows]="false"
      [width]="250"
    ></swiper-showcase>

    <contact-info alignment="end"></contact-info>
  </div>

  <div class="hero-copy">
    <h2 class="welcome-text">
      A full-stack e-commerce developer,<br>
      crafting digital experiences with the Web.<br>

      <span id="location">Based in NJ & NYC</span>
    </h2>

    <div class="ctas">
      <a routerLink="/contact" class="outline">
        <span>Contact Me</span>
      </a>

      <a routerLink="/works">
        <span>View Works</span>
      </a>
    </div>
  </div>
</div>

<div class="showcase">
  <div class="copy-container">
    <h2>Recent Projects</h2>

    <p>
      As of late, I’ve been really helping small businesses get their ecommerce platforms up and running. Have a look at
      some of my recently completed projects!
    </p>
  </div>

  <swiper-showcase
    [loop]="true"
    [perPage]="3"
    [ids]="[1,22,23,4]"
    [spacing]="25"
    [spacing_m]="25"
    [autoplay]="true"
    [delay]="5000"
    [hideTitle]="false"
    [arrows]="true"
  ></swiper-showcase>

  <div class="ctas">
    <a routerLink="/works" class=""><span>View More</span></a>
  </div>
</div>

<div class="toolbox">
  <div class="toolbox-copy">
    <h2>My Toolbox</h2>

    <p>
      I’m well-versed in a multitude of programming languages, frameworks, and design software used to develop web
      applications. This includes web frameworks like React and Angular for custom web build-outs, or Shopify Liquid for
      ecommerce applications.
    </p>
  </div>

  <swiper-toolbox></swiper-toolbox>
</div>

<div class="contact-form">
  <h2>Contact</h2>

  <contact-form></contact-form>
</div>





