<div class="container">
  <h2>Page contents not found</h2>

  <div class="image-container">
    <img src="assets/404.gif" alt="404 Gif">
  </div>

  <a routerLink="/">
    <span>Return Home</span>
  </a>

  <h2>Report this error</h2>

  <div class="contact">
    <contact-form></contact-form>
  </div>
</div>
