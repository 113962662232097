<div class="nav-mask" [class.menu_open]="menuToggle" (click)="toggleMenu()"></div>

<nav class="menu" [class.menu_open]="menuToggle">
  <button class="close-button button" type="button" (click)="toggleMenu()">
    <svg class="close-icon" viewBox="0 0 100 100">
      <g>
        <path d="M16.7,16.7v66.6h66.6V16.7H16.7z M76.3,76.3H23.7V23.7h52.6V76.3z"></path>
        <polygon points="40.8,64.2 50,54.9 59.2,64.2 64.2,59.2 54.9,50 64.2,40.8 59.2,35.8 50,45.1 40.8,35.8 35.8,40.8 45.1,50    35.8,59.2  "></polygon>
      </g>
    </svg>
  </button>

  <h2 class="menu-title">Works</h2>

  <div class="favorite-container">
    <a class="favorites" *ngFor="let work of worksList" [routerLink]="['works', 'project']" [queryParams]="{ id: work.id }">
      <picture>
        <img
          class="thumbnail"
          src="{{ work.thumbnail_src.url }}"
          alt="{{ work.thumbnail_src.alt ? work.thumbnail_src.alt : work.title + ' thumbnail image' }}"
        />
      </picture>
      <h3 class="works-name">{{ work.title }}</h3>
    </a>
  </div>

  <a routerLink="works" class="view_all">View All</a>

  <div class="menu-footer">
    <div class="nav-links">
      <a routerLink="about" class="small">About</a>
      <a routerLink="contact" class="small">Contact</a>
    </div>

    <social-icons></social-icons>
  </div>
</nav>

<div class="container">
  <div class="wrapper">
    <a routerLink="home" class="logo-name-container">
      <svg class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <path d="M0,365.116H131l227-241.86v241.86H500V0H323.4ZM64,469.767H50V365.116H0V500H103V459.3H64v10.465ZM212,365.116H103V500H212V472.093H151V444.186h33V415l-33,.116V395.349h61V365.116Zm106,0H212V500H318V470.93H259V444.186h32V413.953H259V394.186h59v-29.07ZM368,500V365.116H318V500h50ZM500,365.116H439L375,437l64,63h61l-72-67Z"></path>
      </svg>

      <h1 id="name" #typedName></h1>
    </a>

    <ul class="nav-menu">
      <li>
        <a routerLink="works">Works</a>
      </li>

      <li>
        <a routerLink="contact">Contact</a>
      </li>

      <li>
        <a routerLink="about">About</a>
      </li>
    </ul>

    <button class="menu-button button" type="button" (click)="toggleMenu()">
      <svg class="menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xml:space="preserve"
           fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
    <g transform="matrix(1,0,0,1,-390,-30)"><path d="M410,38L410,42L390,42L390,38L410,38ZM410,46L410,50L390,50L390,46L410,46ZM410,30L410,34L390,34L390,30L410,30Z"></path></g></svg>
    </button>
  </div>

</div>


